import {
    CalendarDays,
    Users,
    Presentation,
    BookCopy,
    Music4,
    Lock,
} from "lucide-react";

const FEATURE_MAP = [
    {
        title: "Service Planning",
        icon: <CalendarDays />,
        description:
            "Streamline your service planning with ease, from creating sermon orders to scheduling events.",
    },
    {
        title: "Volunteer Scheduling",
        icon: <Users />,
        description:
            "Effortlessly manage your volunteer scheduling and ensure smooth coordination for every worship service.",
    },
    {
        title: "Beautiful Presentation",
        icon: <Presentation />,
        description:
            "Engage your congregation with captivating church presentations, making worship a visually immersive experience.",
    },
    {
        title: "Song Library",
        icon: <BookCopy />,
        description:
            "Access a vast song library, making it simple to find, revise, organize, and share songs for a seamless worship experience.",
    },
    {
        title: "Music Stand",
        icon: <Music4 />,
        description:
            "Transform your device into a digital music stand, eliminating the need for physical sheet music and enhancing mobility.",
    },
    {
        title: "Roles and Permissions",
        icon: <Lock />,
        description:
            "Customize settings, roles and permissions to empower your team and ensure a secure and tailored experience.",
    },
];

export function HomeFeatures() {
    return (
        <section className="relative">
            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div
                className="pointer-events-none absolute inset-0 top-1/3 bg-slate-900 md:mt-24 lg:mt-0"
                aria-hidden="true"
            ></div>

            <div className="relative mx-auto max-w-6xl px-4 sm:px-6">
                <div className="py-12 md:py-20">
                    {/* Section header */}
                    <div className="mx-auto max-w-3xl pb-12 text-center md:pb-20">
                        <h2 className="mb-4 text-5xl font-extrabold tracking-tighter">
                            Explore the features
                        </h2>
                        <p className="text-xl text-gray-600">
                            See how we can help revolutionizing the way you
                            enhance worship experiences and connect with your
                            congregation.
                        </p>
                    </div>

                    {/* Items */}
                    <div className="mx-auto grid max-w-sm items-start gap-6 md:max-w-2xl md:grid-cols-2 lg:max-w-none lg:grid-cols-3">
                        {FEATURE_MAP.map((feature, index) => (
                            <div
                                className="relative flex flex-col items-center rounded bg-white p-6 shadow-xl"
                                key={index}
                            >
                                <div className="mb-3 rounded-full bg-blue-500 p-4 text-white">
                                    <span className="opacity-70">
                                        {feature.icon}
                                    </span>
                                </div>
                                <h4 className="mb-1 text-xl font-bold leading-snug tracking-tight">
                                    {feature.title}
                                </h4>
                                <p className="text-center text-gray-600">
                                    {feature.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
