"use client";
const Transition = await (await import("@headlessui/react")).Transition;
import { ListChecks, ListOrdered, Flag, Presentation } from "lucide-react";
import { useState, useRef, useEffect } from "react";
import Image from "next/image";

import PlanImage from "@/public/images/Plan.jpg";
import PlanComponent from "@/public/images/Component 11.jpeg";

import OrganizeImage from "@/public/images/Organize.jpg";
import OrganizeComponent from "@/public/images/Component 4.png";

import LeadImage from "@/public/images/Lead.jpg";
import LeadComponent from "@/public/images/Component 5.jpeg";

import ShowImage from "@/public/images/Show.jpg";
import ShowComponent from "@/public/images/Group 5.png";

const FLOW_MAP = [
    {
        title: "Plan",
        description:
            "Map out the order and structure of your worship service, ensuring a meaningful flow.",
        icon: <ListChecks className="w-4" />,
        images: [
            {
                src: PlanImage,
                className: "mx-auto rounded md:max-w-none",
                componentClass: "relative inline-flex flex-col drop-shadow",
            },
            {
                src: PlanComponent,
                containerClass:
                    "absolute transform right-5 animate-float top-[120px]",
                componentClass: "rounded shadow-xl shadow-blue-200",
                className: "",
                width: 440,
                height: 51,
                transitionClass: "delay-100",
            },
        ],
    },
    {
        title: "Oranize",
        description:
            "Gather and arrange sermon notes, song lyrics, and any other necessary materials for a seamless worship experience.",
        icon: <ListOrdered className="w-4" />,
        images: [
            {
                src: OrganizeImage,
                className: "mx-auto rounded md:max-w-none",
                componentClass: "relative inline-flex flex-col drop-shadow",
            },
            {
                src: OrganizeComponent,
                containerClass: "absolute top-[120px] left-[120px]",
                componentClass:
                    "rounded shadow-xl shadow-blue-200 inline-block bg-white border",
                className: "",
                width: 333,
                height: 74,
                transitionClass: "delay-100",
            },
        ],
    },
    {
        title: "Lead",
        description:
            "Guide the congregation through the planned service, delivering sermons, leading prayers, and facilitating worship moments.",
        icon: <Flag className="w-4" />,
        images: [
            {
                src: LeadImage,
                className: "mx-auto rounded md:max-w-none",
                componentClass: "relative inline-flex flex-col drop-shadow",
            },
            {
                src: LeadComponent,
                containerClass: "absolute top-[12px] left-[20px]",
                componentClass:
                    "rounded shadow-xl shadow-blue-200 inline-block bg-white border",
                className: "",
                width: 288,
                height: 428,
                transitionClass: "delay-100",
            },
        ],
    },
    {
        title: "Show",
        description:
            "Utilize engaging visuals and multimedia elements to enhance the worship experience, capturing the attention and hearts of the attendees.",
        icon: <Presentation className="w-4" />,
        images: [
            {
                src: ShowImage,
                className: "mx-auto rounded md:max-w-none",
                componentClass: "relative inline-flex flex-col drop-shadow",
            },
            {
                src: ShowComponent,
                containerClass: "absolute top-[12px] right-10 left-10",
                componentClass:
                    "rounded-lg shadow-xl shadow-blue-200 bg-blue-500",
                className: "",
                width: 621,
                height: 368,
                transitionClass: "delay-100",
            },
        ],
    },
];

export function HomeFlow() {
    const [tab, setTab] = useState<number>(0);

    const tabs = useRef<HTMLDivElement>(null);

    const heightFix = () => {
        if (tabs.current && tabs.current.parentElement)
            tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`;
    };

    useEffect(() => {
        heightFix();
    }, []);

    return (
        <section className="relative">
            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div
                className="pointer-events-none absolute inset-0 mb-16 bg-white"
                aria-hidden="true"
            ></div>
            <div className="absolute left-0 right-0 m-auto h-20 w-px -translate-y-1/2 transform bg-white p-px"></div>

            <div className="relative mx-auto max-w-6xl px-4 sm:px-6">
                <div className="pt-12 md:pt-20">
                    {/* Section header */}
                    <div className="mx-auto max-w-3xl pb-12 text-center md:pb-20">
                        {/* <Badge variant={"outline"}>
                            Powerful suite of tools
                        </Badge> */}
                        <h2 className="mb-4 text-5xl font-extrabold tracking-tighter">
                            Simple flow for everyone
                        </h2>
                        <p className="text-xl text-gray-600">
                            See how we can help revolutionizing the way you
                            enhance worship experiences and connect with your
                            congregation.
                        </p>
                    </div>

                    {/* Section content */}
                    <div className="md:grid md:grid-cols-12 md:gap-6">
                        {/* Content */}
                        <div
                            className="mx-auto max-w-xl md:col-span-7 md:mt-6 md:w-full md:max-w-none lg:col-span-6"
                            data-aos="fade-right"
                        >
                            <div className="mb-8 md:mb-0">
                                {FLOW_MAP.map((flow, index) => (
                                    <a
                                        key={index}
                                        className={`relative mb-3 flex items-center justify-between rounded border p-5 text-lg transition duration-300 ease-in-out ${
                                            tab !== index
                                                ? "border-gray-100 bg-white shadow-md hover:shadow-lg"
                                                : "border-transparent bg-blue-100"
                                        }`}
                                        href="#0"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setTab(index);
                                        }}
                                    >
                                        <div
                                            className={`absolute bottom-0 left-0 top-0 z-0  grid h-full place-items-center p-3 text-8xl font-bold ${
                                                tab == index
                                                    ? "text-white opacity-75"
                                                    : "bg-gradient-to-r from-blue-100 to-green-100 bg-clip-text text-transparent"
                                            }`}
                                        >
                                            {index}
                                        </div>
                                        <div className="relative z-10">
                                            <div className="mb-1 font-bold leading-snug tracking-tight">
                                                {flow.title}
                                            </div>
                                            <div className="text-gray-600">
                                                {flow.description}
                                            </div>
                                        </div>
                                        <div className="ml-3 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-white shadow">
                                            {flow.icon}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>

                        {/* Tabs items */}
                        <div className="mx-auto mb-8 max-w-xl md:order-1 md:col-span-5 md:mb-0 md:w-full md:max-w-none lg:col-span-6">
                            <div
                                className="relative overflow-hidden text-center lg:text-right"
                                data-aos="zoom-y-out"
                                ref={tabs}
                            >
                                {FLOW_MAP.map((flow, index) => {
                                    // if (tab != index) return <></>;
                                    if (flow.images) {
                                        return flow.images.map(
                                            (image, image_index) => (
                                                <Transition
                                                    show={tab === index}
                                                    appear={true}
                                                    className={
                                                        image?.containerClass
                                                    }
                                                    key={image_index}
                                                    enter={`${image?.containerClass} ${image?.transitionClass} transition ease-in-out duration-700 transform order-first`}
                                                    enterFrom={`${image?.containerClass} opacity-0 translate-y-16`}
                                                    enterTo={`${image?.containerClass} opacity-100 translate-y-0`}
                                                    entered={`${image?.containerClass} opacity-100 translate-y-0`}
                                                    leave={`${image?.containerClass} transition ease-in-out duration-300 transform absolute`}
                                                    leaveFrom={`${image?.containerClass} opacity-100 translate-y-0`}
                                                    leaveTo={`${image?.containerClass} opacity-0 -translate-y-16`}
                                                    beforeEnter={() =>
                                                        heightFix()
                                                    }
                                                    unmount={false}
                                                >
                                                    <div
                                                        className={
                                                            image?.componentClass
                                                        }
                                                    >
                                                        <Image
                                                            className={
                                                                image?.className
                                                            }
                                                            src={image.src}
                                                            width={image.width}
                                                            height={
                                                                image.height
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                </Transition>
                                            )
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
