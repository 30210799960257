import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/Input";
import { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url =
    "https://omlyx.us8.list-manage.com/subscribe/post?u=14f6a35737d5f2d0c2e019bb1&amp;id=dfe44e1234&amp;f_id=001b0ae0f0";

const CustomForm = ({ status, message, onValidated }: any) => {
    let email: any;
    const submit = () =>
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
        });

    return (
        <>
            {status === "error" && (
                <div
                    className="rounded border border-red-500 px-5 py-2 text-red-500"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className="rounded border border-green-500 px-5 py-2 text-green-500"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status !== "error" && status !== "success" && (
                <div className="flex w-full flex-col items-center space-y-3 md:flex-row md:space-x-3 md:space-y-0">
                    <Input
                        ref={(node) => (email = node)}
                        type="email"
                        placeholder="Your email"
                    />
                    <Button
                        onClick={submit}
                        disabled={status === "sending"}
                        variant={"blue"}
                        size={"lg"}
                        className="w-full"
                    >
                        {status === "sending" ? "Sending" : "GET EARLY ACCESS"}
                    </Button>
                </div>
            )}
        </>
    );
};

export const HomeHero = () => {
    const [videoModalOpen, setVideoModalOpen] = useState(false);

    return (
        <section className="relative">
            {/* Illustration behind hero content */}
            <div
                className="pointer-events-none absolute bottom-0 left-1/2 z-10 -translate-x-1/2 transform"
                aria-hidden="true"
            >
                <svg
                    width="1360"
                    height="578"
                    viewBox="0 0 1360 578"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <defs>
                        <linearGradient
                            x1="50%"
                            y1="0%"
                            x2="50%"
                            y2="100%"
                            id="illustration-01"
                        >
                            <stop
                                stopColor="#FFF"
                                offset="0%"
                                style={{
                                    stopColor: "var(--color-blue-100)",
                                }}
                            />
                            {/* <stop stopColor="#EAEAEA" offset="77.402%" /> */}
                            <stop
                                stopColor="#DFDFDF"
                                offset="100%"
                                style={{
                                    stopColor: "var(--color-green-100)",
                                }}
                            />
                        </linearGradient>
                    </defs>
                    <g fill="url(#illustration-01)" fillRule="evenodd">
                        <circle cx="1232" cy="228" r="128" />
                        <circle cx="255" cy="443" r="64" />
                        <circle cx="98" cy="98" r="34" />
                    </g>
                </svg>
            </div>

            <div className="prose relative z-20 mx-auto max-w-6xl px-4 sm:px-6">
                {/* Hero content */}
                <div className="pb-12 pt-32 md:pb-20 md:pt-40">
                    {/* Section header */}
                    <div className="pb-12 text-center md:pb-16">
                        <h1
                            className="leading-tighter mb-4 text-5xl font-extrabold tracking-tighter text-black md:text-7xl"
                            data-aos="zoom-y-out"
                        >
                            Unlock the transformative <br />
                            <span className="bg-gradient-to-r from-green-500 to-blue-600 bg-clip-text pr-1 text-transparent">
                                Power of Worship
                            </span>
                        </h1>
                        <div className="mx-auto max-w-3xl">
                            <p
                                className="mb-8 text-xl text-gray-600"
                                data-aos="zoom-y-out"
                                data-aos-delay="150"
                            >
                                Our innovative all-in-one platform empowers
                                churches to optimize worship services through
                                efficient event and service organization,
                                streamlined song management, and seamless
                                presentation coordination.
                            </p>
                            <div>
                                <MailchimpSubscribe
                                    url={url}
                                    render={({
                                        subscribe,
                                        status,
                                        message,
                                    }) => (
                                        <CustomForm
                                            status={status}
                                            message={message}
                                            onValidated={(formData: any) =>
                                                subscribe(formData)
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
