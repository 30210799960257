// import Logo from './logo'

import { Logo } from "@/components/ui/Logo";

export function HomeFooter() {
    const LINKS_MAP = [
        {
            name: "Features",
            links: [
                {
                    title: "Service planning",
                    url: "#0",
                },
                {
                    title: "Volunteer scheduling",
                    url: "#0",
                },
                {
                    title: "Church presentation",
                    url: "#0",
                },
                {
                    title: "Song library",
                    url: "#0",
                },
                {
                    title: "Music stand",
                    url: "#0",
                },
            ],
        },
        {
            name: "Resorces",
            links: [
                {
                    title: "Documentation",
                    url: "#0",
                },
                {
                    title: "Blog",
                    url: "#0",
                },
                {
                    title: "Support center",
                    url: "#0",
                },
            ],
        },
        {
            name: "Company",
            links: [
                {
                    title: "Home",
                    url: "#0",
                },
                {
                    title: "About us",
                    url: "#0",
                },
                {
                    title: "Pricing",
                    url: "#0",
                },
                {
                    title: "Terms",
                    url: "#0",
                },
                {
                    title: "Privacy Policy",
                    url: "#0",
                },
            ],
        },
    ];

    return (
        <footer>
            <div className="mx-auto max-w-6xl px-4 sm:px-6">
                {/* Top area: Blocks */}
                <div className="grid gap-8 border-t border-gray-200 py-8 sm:grid-cols-10 md:py-12">
                    {/* 1st block */}
                    <div className="sm:col-span-12 lg:col-span-4">
                        <div className="mb-2">
                            <Logo />
                        </div>
                        <div className="text-sm text-gray-600">
                            <a
                                href="#0"
                                className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900 hover:underline"
                            >
                                Terms
                            </a>{" "}
                            ·{" "}
                            <a
                                href="#0"
                                className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900 hover:underline"
                            >
                                Privacy Policy
                            </a>
                        </div>
                    </div>

                    {LINKS_MAP.map((group, index) => (
                        <div
                            className="sm:col-span-6 md:col-span-3 lg:col-span-2"
                            key={index}
                        >
                            <h6 className="mb-2 font-medium text-gray-800">
                                {group.name}
                            </h6>
                            <ul className="text-sm">
                                {group.links.map((link, link_index) => (
                                    <li className="mb-2" key={link_index}>
                                        <a
                                            href={link.url}
                                            className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                                        >
                                            {link.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                {/* Bottom area */}
                <div className="border-t border-gray-200 py-4 md:flex md:items-center md:justify-between md:py-8">
                    {/* Copyrights note */}
                    <div className="mr-4 text-sm text-gray-600">
                        &copy; WorshipMS.com. All rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    );
}
