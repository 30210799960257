import type { NextPage } from "next";
import { useSession } from "next-auth/react";
import { FullWidthMainContent, MainContainer } from "../components/organisms/main";
import Link from "next/link";
import { LogoWithText } from "../components/molecules/LogoWithText";
import { HomeFeatures } from "../components/organisms/home/features";
import { HomeHero } from "../components/organisms/home/hero";
import { HomeFlow } from "@/components/organisms/home/flow";
import { HomeFooter } from "@/components/organisms/home/footer";

const Home: NextPage = () => {
    const { data, status } = useSession();

    return (
        <MainContainer>
            <div className="flex min-h-screen flex-col overflow-hidden">
                <div className="my-5 flex w-full justify-center">
                    <div className="mr-4 flex-shrink-0">
                        {/* Logo */}
                        <Link href={data ? "/dashboard" : "/"} passHref>

                            <LogoWithText />

                        </Link>
                    </div>
                </div>
                <FullWidthMainContent>
                    <HomeHero />
                    <HomeFlow />
                    <HomeFeatures />
                    <HomeFooter />
                </FullWidthMainContent>
            </div>
        </MainContainer>
    );
};

export default Home;
